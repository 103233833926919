var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("form-element-group", {
        scopedSlots: _vm._u([
          {
            key: "col-1",
            fn: function() {
              return [
                _c("form-currency-text-field", {
                  attrs: {
                    required: _vm.required,
                    label: _vm.$t("applications.monthly-income"),
                    name: "income",
                    value: _vm.model.income,
                    rules: _vm.required
                      ? [
                          function(v) {
                            return (
                              !!v ||
                              _vm.$t("applications.monthly-income-required")
                            )
                          },
                          function(v) {
                            return (
                              parseInt(v) > 0 ||
                              _vm.$t("applications.monthly-income-required")
                            )
                          }
                        ]
                      : []
                  },
                  on: {
                    blur: function($event) {
                      return _vm.update("income", $event)
                    }
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "col-2",
            fn: function() {
              return [
                _c("form-text-field", {
                  attrs: {
                    required: _vm.required,
                    placeholder: _vm.$t("applications.enter-source-of-income"),
                    rules: _vm.required
                      ? [
                          function(v) {
                            return (
                              !!v ||
                              _vm.$t("applications.source-of-income-required")
                            )
                          }
                        ]
                      : [],
                    icon: "work",
                    value: _vm.model.source,
                    name: "source",
                    label: _vm.$t("applications.source-of-income")
                  },
                  on: {
                    blur: function($event) {
                      return _vm.update("source", $event)
                    }
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }