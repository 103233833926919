<template>
    <div>
        <form-element-group>
            <template v-slot:col-1>
                <form-currency-text-field
                    :required="required"
                    :label="$t('applications.monthly-income')"
                    name="income"
                    :value="model.income"
                    :rules="
                        required
                            ? [
                                  (v) =>
                                      !!v ||
                                      $t(
                                          'applications.monthly-income-required'
                                      ),
                                  (v) =>
                                      parseInt(v) > 0 ||
                                      $t(
                                          'applications.monthly-income-required'
                                      ),
                              ]
                            : []
                    "
                    @blur="update('income', $event)"
                ></form-currency-text-field>
            </template>
            <template v-slot:col-2>
                <form-text-field
                    :required="required"
                    :placeholder="$t('applications.enter-source-of-income')"
                    :rules="
                        required
                            ? [
                                  (v) =>
                                      !!v ||
                                      $t(
                                          'applications.source-of-income-required'
                                      ),
                              ]
                            : []
                    "
                    icon="work"
                    :value="model.source"
                    name="source"
                    :label="$t('applications.source-of-income')"
                    @blur="update('source', $event)"
                ></form-text-field>
            </template>
        </form-element-group>
    </div>
</template>
<script>
export default {
    name: "applicant-other-income",
    components: {},
    props: {
        modelData: {
            type: Object,
            required: true,
            validator: (value) => {
                const params = Object.keys(value);
                return ["source", "income", "id"].every((elem) =>
                    params.includes(elem)
                );
            },
        },
        required: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            model: this.modelData,
        };
    },
    methods: {
        update(key, data) {
            this.model[key] = data;
            this.$emit("update", this.model);
        },
    },
};
</script>

<style scoped>
</style>
